import React, { useContext, useState } from "react";
import {
  IoMdArrowDropleftCircle,
  IoMdArrowDroprightCircle,
} from "react-icons/io";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import airconditioner from "../assets/svg/airconditioner.svg";
import breakfast from "../assets/svg/breakfast.svg";
import foodservice from "../assets/svg/service.svg";
import AuthContext from "../context/AuthProvider";
import { RiSubtractFill } from "react-icons/ri";
import { IoMdAdd } from "react-icons/io";
import { IoIosWifi } from "react-icons/io";
import { PiHairDryerBold } from "react-icons/pi";
import { PiTelevisionSimpleFill } from "react-icons/pi";
import { MdOutlineCoffeeMaker } from "react-icons/md";
import { LuAlarmClock } from "react-icons/lu";

import Hairdryer from "../icons/hairdryer.svg"


function CardsRoom({ Index, data, color }) {

  const [iconDescription, setIconDescription] = useState(null);
  // console.log(data);
  const { Available, RoomNameAvailable, RateChange, currency,
    setDelux, setDeluxAdult,
    setSuperDelux, setSuperDeluxAdult,
    setSuite, setSuiteAdult,
    setPremium, setPremiumAdult,
    setPremiereRetreat, setPremiereRetreatAdult,
    setEliteSuite, setEliteSuiteAdult,
    setGrandDeluxe, setGrandDeluxeAdult,
    setImperialSuite, setImperialSuiteAdult,
    setSupremeRetreat, setSupremeRetreatAdult,
    setRoyalDeluxe, setRoyalDeluxeAdult,
    setPrestigeSuite, setPrestigeSuiteAdult,
    setExclusiveRetreat, setExclusiveRetreatAdult, setRoomCategoryCombination } = useContext(AuthContext)
  const [imgSelect, setImgSelect] = useState("");
  const [previewImage, setPreviewImage] = useState(false);
  const [imgSelectIndex, setImgSelectIndex] = useState(0);
  let [roomCount, setRoomsCount] = useState(0);
  const [roomselect, setroomselect] = useState(false)


  if (data.roomType === "1") { setDeluxAdult(data.adult) }
  if (data.roomType === "2") { setSuperDeluxAdult(data.adult) }
  if (data.roomType === "3") { setSuiteAdult(data.adult) }
  if (data.roomType === "4") { setPremiumAdult(data.adult) }
  if (data.roomType === "5") { setPremiereRetreatAdult(data.adult) }
  if (data.roomType === "6") { setEliteSuiteAdult(data.adult) }
  if (data.roomType === "7") { setGrandDeluxeAdult(data.adult) }
  if (data.roomType === "8") { setImperialSuiteAdult(data.adult) }
  if (data.roomType === "9") { setSupremeRetreatAdult(data.adult) }
  if (data.roomType === "10") { setRoyalDeluxeAdult(data.adult) }
  if (data.roomType === "11") { setPrestigeSuiteAdult(data.adult) }
  if (data.roomType === "12") { setExclusiveRetreatAdult(data.adult) }

  const AddRoomCount = (roomType) => {
    if (roomCount >= Available[RoomNameAvailable[data.roomTypeName]]) {
      setRoomsCount(Available[RoomNameAvailable[data.roomTypeName]])
    }
    else {
      setRoomsCount(roomCount += 1)
      if (roomType === "1") {
        setDelux(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["DELUX"]: data.roomName,
        }));
      }
      if (roomType === "2") {
        setSuperDelux(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["SUPERDELUX"]: data.roomName,
        }));
      }
      if (roomType === "3") {
        setSuite(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["SUITE"]: data.roomName,
        }));
      }
      if (roomType === "4") {
        setPremium(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["PREMIUM"]: data.roomName,
        }));
      }
      if (roomType === "5") {
        setPremiereRetreat(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["PremiereRetreat"]: data.roomName,
        }));
      }
      if (roomType === "6") {
        setEliteSuite(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["EliteSuite"]: data.roomName,
        }));
      }
      if (roomType === "7") {
        setGrandDeluxe(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["GrandDeluxe"]: data.roomName,
        }));
      }
      if (roomType === "8") {
        setImperialSuite(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["ImperialSuite"]: data.roomName,
        }));
      }
      if (roomType === "9") {
        setSupremeRetreat(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["SupremeRetreat"]: data.roomName,
        }));
      }
      if (roomType === "10") {
        setRoyalDeluxe(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["RoyalDeluxe"]: data.roomName,
        }));
      }
      if (roomType === "11") {
        setPrestigeSuite(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["PrestigeSuite"]: data.roomName,
        }));
      }
      if (roomType === "12") {
        setExclusiveRetreat(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["ExclusiveRetreat"]: data.roomName,
        }));
      }
    }
  }

  const DelRoomCount = (roomType) => {
    if (roomCount <= 0) {
      setRoomsCount(0)
    }
    else {
      setRoomsCount(roomCount -= 1)
      if (roomType === "1") {
        setDelux(roomCount)
      }
      if (roomType === "2") {
        setSuperDelux(roomCount)
      }
      if (roomType === "3") {
        setSuite(roomCount)
      }
      if (roomType === "4") {
        setPremium(roomCount)
      }
      if (roomType === "5") {
        setPremiereRetreat(roomCount)
      }
      if (roomType === "6") {
        setEliteSuite(roomCount)
      }
      if (roomType === "7") {
        setGrandDeluxe(roomCount)
      }
      if (roomType === "8") {
        setImperialSuite(roomCount)
      }
      if (roomType === "9") {
        setSupremeRetreat(roomCount)
      }
      if (roomType === "10") {
        setRoyalDeluxe(roomCount)
      }
      if (roomType === "11") {
        setPrestigeSuite(roomCount)
      }
      if (roomType === "12") {
        setExclusiveRetreat(roomCount)
      }
    }
  }

  const facilities = [
    {
      name: "24x7 Air conditioning",
      // icon: ,
      src: airconditioner,
      isDisplay: data.roomFacilities.airConditonar,
    },
    {
      name: "Complimentary Breakfast",
      // icon: breakfast,
      src: breakfast,
      isDisplay: data.roomFacilities.freeBreakfast,
    },
    {
      name: "wifi",
      icon: <IoIosWifi />,
      src: "",
      isDisplay: data.roomFacilities.wifi,
    },
    {
      name: "television",
      icon: <PiTelevisionSimpleFill />,
      src: "",
      isDisplay: data.roomFacilities.television,
    },
    {
      name: "hairdryers",
      icon: "",
      src: Hairdryer,
      isDisplay: data.roomFacilities.hairdryers,
    },
    {
      name: "coffee maker",
      icon: <MdOutlineCoffeeMaker />,
      src: "",
      isDisplay: data.roomFacilities.coffeeMakers,
    },
    {
      name: "direct dial",
      icon: "",
      src: "",
      isDisplay: data.roomFacilities.directDial,
    },
    {
      name: "table with chair",
      icon: "",
      src: "",
      isDisplay: data.roomFacilities.tableWithChair,
    },
    {
      name: "alarm clock",
      icon: <LuAlarmClock />,
      src: "",
      isDisplay: data.roomFacilities.alarmclock,
    },
    {
      name: "electronic locker",
      icon: "",
      src: "", isDisplay: data.roomFacilities.electronicLocker,
    },
    {
      name: "fridge",
      icon: "",
      src: "", isDisplay: data.roomFacilities.fridge,
    },
    {
      name: "bathroom with shower",
      icon: "",
      src: "", isDisplay: data.roomFacilities.bathroomWithShower,
    },
    {
      name: "free breakfast",
      icon: "",
      src: "", isDisplay: data.roomFacilities.freeBreakfast,
    },
    {
      name: "kid equipment",
      icon: "",
      src: "", isDisplay: data.roomFacilities.kidEquipment,
    },
    {
      name: "balcony",
      icon: "",
      src: "", isDisplay: data.roomFacilities.Balcony,
    },
    {
      name: "bath",
      icon: "",
      src: "", isDisplay: data.roomFacilities.Bath,
    },
    {
      name: "electric kettle",
      icon: "",
      src: "", isDisplay: data.roomFacilities.Electrickettle,
    },
    {
      name: "dressing room",
      icon: "",
      src: "", isDisplay: data.roomFacilities.Dressingroom,
    },
    {
      name: "fan",
      icon: "",
      src: "", isDisplay: data.roomFacilities.Fan,
    },
    {
      name: "fireplace",
      icon: "",
      src: "", isDisplay: data.roomFacilities.Fireplace,
    },
    {
      name: "heating",
      icon: "",
      src: "", isDisplay: data.roomFacilities.Heating,
    },
    {
      name: "iron",
      icon: "",
      src: "", isDisplay: data.roomFacilities.Iron,
    },
    {
      name: "pajamas",
      icon: "",
      src: "", isDisplay: data.roomFacilities.Pajamas,
    },
    {
      name: "toilet paper",
      icon: "",
      src: "", isDisplay: data.roomFacilities.Toilet_paper,
    },
    {
      name: "bidet",
      icon: "",
      src: "", isDisplay: data.roomFacilities.Bidet,
    },
    {
      name: "bath shower",
      icon: "",
      src: "", isDisplay: data.roomFacilities["Bath_shower"],
    },
    {
      name: "bathrobe",
      icon: "",
      src: "", isDisplay: data.roomFacilities.Bathrobe,
    },
    {
      name: "free toiletries",
      icon: "",
      src: "", isDisplay: data.roomFacilities["Free_toiletries"],
    },
    {
      name: "minibar",
      icon: "",
      src: "", isDisplay: data.roomFacilities.Minibar,
    },
    {
      name: "kitchen",
      icon: "",
      src: "", isDisplay: data.roomFacilities.Kitchen,
    },
    {
      name: "city view",
      icon: "",
      src: "", isDisplay: data.roomFacilities["City_view"],
    },
    {
      name: "garden view",
      icon: "",
      src: "", isDisplay: data.roomFacilities["Garden_view"],
    },
    {
      name: "lake view",
      icon: "",
      src: "", isDisplay: data.roomFacilities["Lake_view"],
    },
    {
      name: "river view",
      icon: "",
      src: "", isDisplay: data.roomFacilities["River_view"],
    },
    {
      name: "sea view",
      icon: "",
      src: "", isDisplay: data.roomFacilities["Sea_view"],
    }
  ]

  return (
    <div
      className="lg:grid lg:grid-cols-3 flex flex-col gap-4 bg-gradient-to-t from-[#FAF8F5] w-full pe-1 overflow-hidden mb-10 shadow-xl rounded-md"
      style={{ backgroundImage: `linear-gradient(to top, #FAF8F5, ${color}30)` }}
    >
      <div className="lg:col-span-1 relative">
        <Swiper
          // loop={true}
          slidesPerView={1}
          // autoplay={{
          //   delay: 4000,
          // }}
          // pagination={{
          //   el: `#bullets-rooms-${Index}`,
          // }}
          pagination={{ clickable: true }}
          navigation={true}
          modules={[Pagination, Autoplay, Navigation]}
          breakpoints={{
            768: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
          }}
          className="room_slider"
        >
          {data.roomImage.map((details, index) => {
            return (
              <SwiperSlide key={index}>
                <div
                  className="lg:h-[220px] h-auto cursor-pointer relative"
                  onClick={() => {
                    setImgSelect([...data.roomImage]);
                    setPreviewImage(true);
                  }}
                >
                  <img src={details} alt="img" className="w-full h-full" />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        {previewImage && (
          <ImagePreview
            imgSelectIndex={imgSelectIndex}
            imgSelect={imgSelect}
            setImgSelectIndex={setImgSelectIndex}
            setPreviewImage={setPreviewImage}
          />
        )}
        {/* <div id={`bullets-rooms-${Index}`} className="w-fit mx-auto"></div> */}
      </div>

      <div className="lg:col-span-2 max-md:p-4 pt-3 lg:pe-3 flex flex-col justify-center">
        <div className="flex flex-row items-center justify-between sm:items-center font-bold">
          <div className="flex sm:flex-row flex-col sm:items-center sm:gap-6 gap-1 max-md:w-[150px]">
            <h2 className="sm:text-3xl text-xl text-blue-950">{data.roomName}</h2>
            {/* <p className="font-bold sm:text-sm text-xs">
              500 sq/ft, 3 guests per room
            </p> */}
          </div>
          <span className="text-[#FF0606] italic text-xs max-md:hidden">
            {Available[RoomNameAvailable[data.roomTypeName]]} rooms available
          </span>
          <p className="text-2xl max-md:text-xl font-bold text-blue-950">{roomCount * RateChange[data.roomType]?.Price === 0 ? RateChange[data.roomType]?.Price : roomCount * RateChange[data.roomType]?.Price} {currency}<small className="font-light text-sm">/per night</small></p>
        </div>

        <p className="mt-4 sm:text-base text-sm text-justify text-zinc-800">
          {data.roomDescription}
        </p>

        <div className="grid md:grid-cols-5 gap-5 mt-4">
          <div className="md:col-span-4"
          // style={{ backgroundColor: color }}
          >
            {/* <h2 className="text-gray-700 text-lg font-bold">Amenities</h2> */}
            <div className="flex flex-wrap gap-1 mt-4">
              {facilities.map((details, index) =>
                details.isDisplay && (
                  <div className="flex items-center relative" key={index}>
                    <div
                      className="cursor-pointer w-6 aspect-square flex items-center justify-center"
                      onMouseEnter={() => setIconDescription(index)}
                      onMouseLeave={() => setIconDescription(null)}
                    >
                      {details.src && <img alt="facilitiesIcon" src={details.src} width={18} />}
                      {details.icon && <span className="text-xl text-gray-500">{details.icon}</span>}

                    </div>
                    {iconDescription === index && (
                      <p className="text-sm absolute tracking-wider rounded-md w-max bottom-8 left-0 transform translate-x-0 py-1 px-2 bg-slate-800 text-white">
                        {details.name}
                      </p>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
          <div className="md:col-span-1">
            <div className="flex flex-col md:items-end" style={{ color: color }}>
              {/* <p className="text-2xl font-bold">{roomCount * RateChange[data.roomType]?.Price === 0 ? RateChange[data.roomType]?.Price : roomCount * RateChange[data.roomType]?.Price} {currency}</p> */}
              {/* <span className="text-xs">per night</span> */}
            </div>
            {roomCount !== 0 && Available[RoomNameAvailable[data.roomTypeName]] !== 0 ?
              <div className="flex justify-between lg:items-end  gap-5 mt-4">
                {/* <p className="text-[#000000] font-bold">Room(s)</p> */}
                <span className="text-[#FF0606] italic text-base lg:hidden">
                  {Available[RoomNameAvailable[data.roomTypeName]]} rooms available
                </span>
                <div className=" text-[#000000] mr-0 ml-auto flex justify-between gap-3 items-center rounded-lg overflow-hidden font-normal bg-slate-300"
                // style={{ backgroundColor: color }}
                >
                  <button
                    className="text-xl h-10 aspect-square flex hover:bg-red-600/30 active:bg-red-700 transition duration-300 ease-in-out items-center justify-center text-white cursor-pointer bg-red-600"
                    onClick={() => {
                      DelRoomCount(data.roomType)
                    }}
                  >
                    <RiSubtractFill />
                  </button>
                  <span className="text-xl w-10 aspect-square flex items-center justify-center"> {roomCount}</span>
                  <button
                    className="text-xl h-10 aspect-square flex items-center hover:bg-[#005BA4]/30 active:bg-[#005BA4] transition duration-300 ease-in-out bg-[#005BA4] text-white justify-center cursor-pointer"
                    onClick={() => AddRoomCount(data.roomType)}
                  >
                    <IoMdAdd />
                  </button>
                </div>
              </div> : Available[RoomNameAvailable[data.roomTypeName]] == 0 ?
                <div>
                  <button className="bg-[#FF0000] text-white uppercase py-2 mt-4 w-full  text-lg font-bold">
                    Sold Out
                  </button>
                </div> :
                <div>
                  <button onClick={() => { AddRoomCount(data.roomType) }} className=" text-white rounded-md uppercase py-2 mt-4 w-full text-base font-semibold" style={{ backgroundColor: color }}>
                    Add Room
                  </button>
                </div>}
          </div>
        </div>
      </div>
    </div>
  );
}





const ImagePreview = ({
  imgSelectIndex,
  imgSelect,
  setImgSelectIndex,
  setPreviewImage,
}) => {
  const slideRight = () => {
    if (imgSelectIndex === imgSelect.length - 1) return setImgSelectIndex(0);
    setImgSelectIndex(imgSelectIndex + 1);
  };

  const slideLeft = () => {
    if (imgSelectIndex <= 0) return setImgSelectIndex(imgSelect.length - 1);
    setImgSelectIndex(imgSelectIndex - 1);
  };
  return (
    <div className="fixed top-0 left-0 w-[100%] h-[100vh] bg-[#000000c6] z-50 flex flex-col justify-center items-center">
      <div className="relative w-[100%] h-[50%] md:w-[90%] md:h-[90%] m-auto ">
        <img alt="facilitiesIcon"
          src={imgSelect[imgSelectIndex]}
          loading="lazy"
          decoding="async"
          aria-hidden="true"
          // alt={""}
          style={{
            width: "100%",
            height: "100%",
          }}
          className="z-50"
        />
        <p
          className="text-2xl text-white bg-slate-900 w-10 h-10 flex justify-center items-center rounded-full mb-4 cursor-pointer absolute top-[-2.8%] right-[-1%]"
          onClick={() => {
            setPreviewImage(false);
            window.document.body.classList.remove("bg_salmon");
          }}
        >
          X
        </p>
      </div>
      <span
        className="fixed top-[50%] md:left-[2rem] left-[4rem] p-4 bg-[#DEB666] cursor-pointer rounded-lg"
        onClick={() => {
          slideLeft();
        }}
      >
        <IoMdArrowDropleftCircle size={20} />
      </span>
      <span
        className="fixed top-[50%] md:right-[2rem] right-[4rem] p-4 bg-[#DEB666] cursor-pointer rounded-lg"
        onClick={() => {
          slideRight();
        }}
      >
        <IoMdArrowDroprightCircle size={20} />
      </span>
    </div>
  );
};

export default CardsRoom;

//data.roomFacilities

// wifi
// television
// airConditonar
// hairdryers
// coffeeMakers
// directDial
// tableWithChair
// alarmclock
// electronicLocker
// fridge
// bathroomWithShower
// freeBreakfast
// kidEquipment
// Balcony
// Bath
// Electrickettle
// Dressingroom
// Fan
// Fireplace
// Heating
// Iron
// Pajamas
// Toilet_paper
// Bidet
// Bath_shower
// Bathrobe
// Free_toiletries
// Minibar
// Kitchen
// City_view
// Garden_view
// Lake_view
// River_view
// Sea_view
