import React, { useContext, useState } from "react";

import useRazorpay from "react-razorpay";
import visa from "../assets/visa.png";
import AuthContext from "../context/AuthProvider";

function CheckoutDetails({ color }) {
  const { linktohit, selectedDate, nextselectedDate, Nights,
    Delux, SuperDelux,
    Suite, Premium,
    PremiereRetreat, EliteSuite,
    GrandDeluxe, ImperialSuite,
    SupremeRetreat, RoyalDeluxe,
    PrestigeSuite,
    ExclusiveRetreat, RoomCategoryCombination, Adult,
    kids, Subtotal, Taxes,
    Grandtotal, currency, RateChange,
    maxAdult, setSubtotal,
    setTaxes,
    setGrandtotal, setmaxAdult, DeluxAdult,
    SuperDeluxAdult,
    SuiteAdult,
    PremiumAdult,
    PremiereRetreatAdult,
    EliteSuiteAdult,
    GrandDeluxeAdult,
    ImperialSuiteAdult,
    SupremeRetreatAdult,
    RoyalDeluxeAdult,
    PrestigeSuiteAdult,
    ExclusiveRetreatAdult, addTax, isOnlinepay,
    isPayatHotel,
    isSemiPayment, GatewayConnected, HotelName,
    websiteData, setPayment } = useContext(AuthContext);


  const [Name, setName] = useState("")
  const [Phone, setPhone] = useState("")
  const [Email, setEmail] = useState("")
  const [ispaymentProcessing, setispaymentProcessing] = useState(false)
  const [PaymentStatus, setPaymentStatus] = useState("PENDING")
  const [PayStatus, setPayStatus] = useState("PENDING")
  const [OrderId, setOrderId] = useState("")
  const [RedirectLink, setRedirectLink] = useState("")

  const [Razorpay, createOrder] = useRazorpay();


  // Loader

  const [loader_25, setLoader_25] = useState(false)
  const [loader_50, setLoader_50] = useState(false)
  const [loader_100, setLoader_100] = useState(false)

  try {
    var deluxcost = Delux * Number(RateChange["1"]["Price"]);
  } catch {
    deluxcost = 0;
  }
  try {
    var sdcost = SuperDelux * Number(RateChange["2"]["Price"]);
  } catch {
    sdcost = 0;
  }
  try {
    var suitecost = Suite * Number(RateChange["3"]["Price"]);
  } catch {
    suitecost = 0;
  }
  try {
    var premiumcost = Premium * Number(RateChange["4"]["Price"]);
  } catch {
    premiumcost = 0;
  }
  try {
    var premiereretreatcost =
      PremiereRetreat * Number(RateChange["5"]["Price"]);
  } catch {
    premiereretreatcost = 0;
  }
  try {
    var elitesuitecost =
      EliteSuite * Number(RateChange["6"]["Price"]);
  } catch {
    elitesuitecost = 0;
  }
  try {
    var granddeluxecost =
      GrandDeluxe * Number(RateChange["7"]["Price"]);
  } catch {
    granddeluxecost = 0;
  }
  try {
    var imperialsuitecost =
      ImperialSuite * Number(RateChange["8"]["Price"]);
  } catch {
    imperialsuitecost = 0;
  }
  try {
    var supremeretreatcost =
      SupremeRetreat * Number(RateChange["9"]["Price"]);
  } catch {
    supremeretreatcost = 0;
  }
  try {
    var royaldeluxecost =
      RoyalDeluxe * Number(RateChange["10"]["Price"]);
  } catch {
    royaldeluxecost = 0;
  }
  try {
    var prestigesuitecost =
      PrestigeSuite * Number(RateChange["11"]["Price"]);
  } catch {
    prestigesuitecost = 0;
  }
  try {
    var exclusiveretreatcost =
      ExclusiveRetreat * Number(RateChange["12"]["Price"]);
  } catch {
    exclusiveretreatcost = 0;
  }

  let tax = 0
  let cost =
    Number(deluxcost) +
    Number(sdcost) +
    Number(suitecost) +
    Number(premiumcost) +
    Number(premiereretreatcost) +
    Number(elitesuitecost) +
    Number(granddeluxecost) +
    Number(imperialsuitecost) +
    Number(supremeretreatcost) +
    Number(royaldeluxecost) +
    Number(prestigesuitecost) +
    Number(exclusiveretreatcost)

  if (currency == "INR") {
    if (addTax) {
      tax = 0.18 * Number(cost);
    }
    else {
      tax = 0;
    }

  }
  else {

  }

  if (currency == "INR") {
    tax = 0.18 * Number(cost);
  }
  else {
    tax = 0;
  }

  setSubtotal(cost)
  setTaxes(tax)
  setGrandtotal(Number(cost) + Number(tax))

  const changeDateFormat = (inputdate) => {
    var date = new Date(inputdate);
    // Get day, month, and year
    var day = date.getDate();
    var month = date.getMonth() + 1; // Months are zero-based
    var year = date.getFullYear();

    // Pad day and month with leading zeros if needed
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;

    // Format the date as 'DD-MM-YYYY'
    var formattedDate = day + "-" + month + "-" + year;

    return formattedDate;
  };

  const BookFinalize = () => {
    let madult = 0
    if (Delux > 0) { setmaxAdult(madult += Delux * DeluxAdult) }
    if (SuperDelux > 0) { setmaxAdult(madult += SuperDelux * SuperDeluxAdult) }
    if (Suite > 0) { setmaxAdult(madult += Suite * SuiteAdult) }
    if (Premium > 0) { setmaxAdult(madult += Premium * PremiumAdult) }
    if (PremiereRetreat > 0) { setmaxAdult(madult += PremiereRetreat * PremiereRetreatAdult) }
    if (EliteSuite > 0) { setmaxAdult(madult += EliteSuite * EliteSuiteAdult) }
    if (GrandDeluxe > 0) { setmaxAdult(madult += GrandDeluxe * GrandDeluxeAdult) }
    if (ImperialSuite > 0) { setmaxAdult(madult += ImperialSuite * ImperialSuiteAdult) }
    if (SupremeRetreat > 0) { setmaxAdult(madult += SupremeRetreat * SupremeRetreatAdult) }
    if (RoyalDeluxe > 0) { setmaxAdult(madult += RoyalDeluxe * RoyalDeluxeAdult) }
    if (PrestigeSuite > 0) { setmaxAdult(madult += PrestigeSuite * PrestigeSuiteAdult) }
    if (ExclusiveRetreat > 0) { setmaxAdult(madult += ExclusiveRetreat * ExclusiveRetreatAdult) }


    if (Adult <= madult) {
      return true
    } else {
      return false
    }

  }

  const CreateBookingsemiHalf = async () => {
    if (Name === "" || Email === "" || Phone === "") {
      alert("Please fill the form before submit");
    }
    else {
      setLoader_25(true)
      setispaymentProcessing(true);
      setPaymentStatus("ADVANCED");
      setPayStatus("25% PAID");
      let halfcost = 0.25 * Grandtotal;
      const response = await fetch(`${linktohit}/payment/create_order`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, /",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          roomNumbers: [],
          hId: localStorage.getItem("hid"),
          ndid: localStorage.getItem("hotelid"),
          amount: halfcost,
          currency: currency,
          guestInfo: {
            guestName: Name,
            EmailId: Email,
            Phone: Phone,
            City: "-",
            Country: { "value": "IN", "label": "India" },
            address: "-",
          },
          Adults: Adult,
          Kids: kids,
          Bookings: [
            { RoomType: "1", Qty: Delux },
            { RoomType: "2", Qty: SuperDelux },
            { RoomType: "3", Qty: Suite },
            { RoomType: "4", Qty: Premium },
            { RoomType: "5", Qty: PremiereRetreat },
            { RoomType: "6", Qty: EliteSuite },
            { RoomType: "7", Qty: GrandDeluxe },
            { RoomType: "8", Qty: ImperialSuite },
            { RoomType: "9", Qty: SupremeRetreat },
            { RoomType: "10", Qty: RoyalDeluxe },
            { RoomType: "11", Qty: PrestigeSuite },
            { RoomType: "12", Qty: ExclusiveRetreat },
          ],
          payment: {
            Status: "PENDING",
            RefNo: "",
            PaymentProvider: "RazorPay",
            Mode: "Online",
          },
          mealPlan: {
            PackageId: "NA",
            PackageName: "NA",
            PackagePrice: "NA",
            PackageperRoom: "NA",
          },
          promocode: {
            PromoId: "NA",
            Code: "NA",
            Discount: "NA",
          },
          packages: {
            packageId: "NA",
            packageName: "NA",
            packagePrice: "NA",
            specialRequest: "NA",
          },
          checkIn: selectedDate,
          checkOut: nextselectedDate,
          price: {
            amountPay: halfcost,
            Principal: Subtotal,
            Tax: tax,
            Total: Grandtotal,
          },
          isCheckedIn: false,
          isCheckedOut: false,
        }),
      });

      const json = await response.json();
      console.log(json)
      if (json.Status === true) {
        setLoader_25(false)
        setOrderId(json.order_id);
        setRedirectLink(json.redirectLink);
        setispaymentProcessing(false);
        HandlePaymentRazorpay(json.order_id, halfcost, "ADVANCED")
      } else {
        alert("Some Problem")
      }
      setLoader_25(false)
    }
  }

  const CreateBookingHalf = async () => {
    if (Name === "" || Email === "" || Phone === "") {
      alert("Please fill the form before submit")
    }
    else {
      setLoader_50(true);
      setispaymentProcessing(true);
      setPaymentStatus("ADVANCED");
      setPayStatus("HALF PAID");
      let halfcost = 0.5 * Grandtotal;
      const response = await fetch(`${linktohit}/payment/create_order`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, /",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          roomNumbers: [],
          hId: localStorage.getItem("hid"),
          ndid: localStorage.getItem("hotelid"),
          amount: halfcost,
          currency: currency,
          guestInfo: {
            guestName: Name,
            EmailId: Email,
            Phone: Phone,
            City: "-",
            Country: { "value": "IN", "label": "India" },
            address: "-",
          },
          Adults: Adult,
          Kids: kids,
          Bookings: [
            { RoomType: "1", Qty: Delux },
            { RoomType: "2", Qty: SuperDelux },
            { RoomType: "3", Qty: Suite },
            { RoomType: "4", Qty: Premium },
            { RoomType: "5", Qty: PremiereRetreat },
            { RoomType: "6", Qty: EliteSuite },
            { RoomType: "7", Qty: GrandDeluxe },
            { RoomType: "8", Qty: ImperialSuite },
            { RoomType: "9", Qty: SupremeRetreat },
            { RoomType: "10", Qty: RoyalDeluxe },
            { RoomType: "11", Qty: PrestigeSuite },
            { RoomType: "12", Qty: ExclusiveRetreat },
          ],
          payment: {
            Status: "PENDING",
            RefNo: "",
            PaymentProvider: "RazorPay",
            Mode: "Online",
          },
          mealPlan: {
            PackageId: "NA",
            PackageName: "NA",
            PackagePrice: "NA",
            PackageperRoom: "NA",
          },
          promocode: {
            PromoId: "NA",
            Code: "NA",
            Discount: "NA",
          },
          packages: {
            packageId: "NA",
            packageName: "NA",
            packagePrice: "NA",
            specialRequest: "NA",
          },
          checkIn: selectedDate,
          checkOut: nextselectedDate,
          price: {
            amountPay: halfcost,
            Principal: Subtotal,
            Tax: tax,
            Total: Grandtotal,
          },
          isCheckedIn: false,
          isCheckedOut: false,
        }),
      });

      const json = await response.json();
      console.log(json)
      if (json.Status === true) {
        setLoader_50(false)
        setOrderId(json.order_id);
        setRedirectLink(json.redirectLink);
        setispaymentProcessing(false);
        HandlePaymentRazorpay(json.order_id, halfcost, "ADVANCED")
      } else {
        alert("Some Problem");
        setLoader_50(false)
      }
    }
  }

  const CreateBookingFull = async () => {
    if (Name === "" || Email === "" || Phone === "") {
      alert("Please fill the form before submit")
    }
    else {

      alert("You are in the else")
      setLoader_100(true)
      setispaymentProcessing(true);
      setPaymentStatus("SUCCESS");
      setPayStatus("FULL PAID");
      let halfcost = 1 * Grandtotal;
      const response = await fetch(`${linktohit}/payment/create_order`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, /",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          roomNumbers: [],
          hId: localStorage.getItem("hid"),
          ndid: localStorage.getItem("hotelid"),
          amount: halfcost,
          currency: currency,
          guestInfo: {
            guestName: Name,
            EmailId: Email,
            Phone: Phone,
            City: "-",
            Country: { "value": "IN", "label": "India" },
            address: "-",
          },
          Adults: Adult,
          Kids: kids,
          Bookings: [
            { RoomType: "1", Qty: Delux },
            { RoomType: "2", Qty: SuperDelux },
            { RoomType: "3", Qty: Suite },
            { RoomType: "4", Qty: Premium },
            { RoomType: "5", Qty: PremiereRetreat },
            { RoomType: "6", Qty: EliteSuite },
            { RoomType: "7", Qty: GrandDeluxe },
            { RoomType: "8", Qty: ImperialSuite },
            { RoomType: "9", Qty: SupremeRetreat },
            { RoomType: "10", Qty: RoyalDeluxe },
            { RoomType: "11", Qty: PrestigeSuite },
            { RoomType: "12", Qty: ExclusiveRetreat },
          ],
          payment: {
            Status: "PENDING",
            RefNo: "",
            PaymentProvider: "RazorPay",
            Mode: "Online",
          },
          mealPlan: {
            PackageId: "NA",
            PackageName: "NA",
            PackagePrice: "NA",
            PackageperRoom: "NA",
          },
          promocode: {
            PromoId: "NA",
            Code: "NA",
            Discount: "NA",
          },
          packages: {
            packageId: "NA",
            packageName: "NA",
            packagePrice: "NA",
            specialRequest: "NA",
          },
          checkIn: selectedDate,
          checkOut: nextselectedDate,
          price: {
            amountPay: halfcost,
            Principal: Subtotal,
            Tax: tax,
            Total: Grandtotal,
          },
          isCheckedIn: false,
          isCheckedOut: false,
        }),
      });

      const json = await response.json();
      console.log(json)
      if (json.Status === true) {
        setLoader_100(false);
        setOrderId(json.order_id);
        setRedirectLink(json.redirectLink);
        setispaymentProcessing(false);
        HandlePaymentRazorpay(json.order_id, halfcost, "SUCCESS")
      } else {
        alert("Some Problem");
        setLoader_100(false)
      }
    }
  }

  const UpdateBooking = async (orderid, payid, status) => {
    console.log({
      ndid: localStorage.getItem("hotelid"),
      orderid: orderid,
      paymentid: payid,
      Status: status,
      hId: localStorage.getItem("hid"),
    })
    const response = await fetch(`${linktohit}/booking/update`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, /",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ndid: localStorage.getItem("hotelid"),
        orderid: orderid,
        paymentid: payid,
        Status: status,
        hId: localStorage.getItem("hid"),
      }),
    });
    const json = await response.json();
    console.log(json)


  }

  const HandlePaymentRazorpay = (orderID, amnt, status) => {
    try {
      // alert(props.GatewayConnected)
      const mockOrderData = {
        amount: parseInt(Number(amnt)) * 100, // Convert amount to paise (assuming INR)
        orderId: orderID, // Generate a unique order ID
      };

      const options = {
        key: GatewayConnected.API_KEY, // Enter the Key ID generated from the Dashboard rzp_test_UZ0V9jh3jMC0C9,rzp_live_5uaIIwZcxLC70j
        amount: mockOrderData.amount.toString(), // Use the amount from the order data
        currency: currency,
        name: HotelName,
        description: "Test Transaction",
        image: websiteData?.Footer?.Logo,
        order_id: OrderId, // Use the order ID from the order data
        handler: async function (response) {
          setOrderId(response.razorpay_order_id);
          UpdateBooking(orderID, response.razorpay_payment_id, status);
          setPayment({
            Status: true,
            Logo: websiteData?.Footer?.Logo,
            HotelName: HotelName,
            Order: orderID,
            Payment: response.razorpay_payment_id,
            Name: Name,
            Phone: Phone,
            Email: Email,
            City: "",
            Country: "",
            Checkin: selectedDate,
            Checkout: nextselectedDate,
            Adult: Adult,
            Kid: kids,
            Tax: tax,
            Total: Subtotal,
            Grandtotal: Grandtotal,
            Paid: amnt,
            PayStatus: status,
            Delux: Delux,
            Sd: SuperDelux,
            Suite: Suite,
            Premium: Premium,
            PremiereRetreat: PremiereRetreat,
            EliteSuite: EliteSuite,
            GrandDeluxe: GrandDeluxe,
            ImperialSuite: ImperialSuite,
            SupremeRetreat: SupremeRetreat,
            RoyalDeluxe: RoyalDeluxe,
            PrestigeSuite: PrestigeSuite,
            ExclusiveRetreat: ExclusiveRetreat,
            MealPlan: "",
            Mealprice: "",
            Rooms: RoomCategoryCombination,
          });
        },

        theme: {
          color: "#978667",
        },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
      });

      rzp1.open();
    } catch (error) {
      console.log("Payment Error:", error);
    }
  }


  return (
    <div id="summery" className="maxwidth px-5 !mt-10">

      <div className="flex lg:flex-row flex-col gap-8 py-4">

        <div className="lg:w-[60%] py-4 max-md:order-2">
          <h2 className="text-3xl capitalize  max-md:text-center text-white  py-2 font-bold" style={{ color: color }}>
            Checkout details
          </h2>
          <div className="flex flex-col gap-4 mt-8 ">
            <div className="">
              <div className="md:flex flex-col gap-5 ">
                <div className="flex max-md:flex-col  md:grid-cols-3 md:grid ">
                  <label
                    htmlFor="name"
                    className="flex items-center text-lg"
                  >
                    Name <span className="text-red-600">*</span>
                  </label>
                  <input
                    id="name"
                    type="text"
                    placeholder="enter your full name"
                    value={Name}
                    required
                    onChange={(e) => { setName(e.target.value) }}
                    className="outline-none border   rounded-md placeholder:capitalize px-4 py-3 col-span-2 shadow-sm shadow-[#978667]  text-sm w-full"
                    style={{ border: `1px solid ${color}` }}
                  />
                </div>
                <div className="flex max-md:flex-col  md:grid-cols-3 md:grid max-md:mt-5  ">
                  <label
                    htmlFor="email"
                    className="flex items-center text-lg"
                  >
                    Email <span className="text-red-600">*</span>

                  </label>
                  <input
                    id="email"
                    type="text"
                    placeholder="enter your email"
                    value={Email}
                    required
                    onChange={(e) => { setEmail(e.target.value) }}
                    className="outline-none border  rounded-md placeholder:capitalize  px-4 py-3  col-span-2  shadow-sm shadow-[#978667] text-sm w-full"
                    style={{ border: `1px solid ${color}` }}
                  />
                </div>
                <div className="flex max-md:flex-col  md:grid-cols-3 md:grid max-md:mt-5 ">
                  <label
                    htmlFor="phone"
                    className="flex items-center text-lg whitespace-nowrap"
                  >
                    Phone no <span className="text-red-600">*</span>
                  </label>
                  <input
                    id="phone"
                    type="text"
                    placeholder="enter your phone number"
                    value={Phone}
                    required
                    maxLength={10}
                    onChange={(e) => { setPhone(e.target.value) }}
                    className="outline-none border   rounded-md  px-4 py-3 col-span-2 placeholder:capitalize  shadow-sm shadow-[#978667]  text-sm w-full"
                    style={{ border: `1px solid ${color}` }}
                  />
                </div>
                <div className="flex max-md:flex-col  md:grid-cols-3 md:grid max-md:mt-5">
                  <label
                    htmlFor="message"
                    className="flex text-lg whitespace-nowrap"
                  >
                    Special Requests
                  </label>
                  <textarea
                    id="message"
                    rows={2}
                    placeholder="Enter any special requests"
                    className="outline-none border rounded-md resize-none  px-4 py-4  col-span-2 placeholder:capitalize  shadow-sm shadow-[#978667]  text-sm w-full"
                    style={{ border: `1px solid ${color}` }}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-6 mt-10">





                {/* <div className="flex sm:flex-row flex-col gap-2 items-center justify-between">
                  <h2 className="sm:text-xl">Special codes or gift coupons</h2>
                  <input
                    type="text"
                    placeholder="enter your coupon code"
                    className="outline-none border   px-4 py-2   shadow-sm shadow-[#978667] italic text-sm sm:w-auto w-full"
                  />
                </div> */}
                {BookFinalize() ?
                  isOnlinepay ? <>
                    {isSemiPayment ? (
                      <button onClick={() => { CreateBookingsemiHalf() }} className="min-h-[58px] border   rounded-lg shadow-sm shadow-[#978667] flex items-center justify-around" style={{ border: `1px solid ${color}` }}>
                        {loader_25 ? (
                          <div className="flex justify-center items-center">
                            <div role="status">
                              <svg
                                aria-hidden="true"
                                className="w-6 h-6 text-gray-200 animate-spin fill-[#978667]"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          <>
                            <h2 className="font-bold text-xl">Pay 25% amount</h2>
                            <p className="text-[#039E00] font-bold text-xl">{0.25 * Grandtotal} {currency}</p>
                          </>
                        )}
                      </button>
                    ) : null}
                    {/* booking buttons */}
                    {/* <button onClick={() => { CreateBookingHalf() }} className="min-h-[58px] border w-max  rounded-lg shadow-sm shadow-[#978667] flex items-center justify-around" style={{ border: `1px solid ${color}` }}>
                      {loader_50 ? (
                        <div className="flex justify-center items-center">
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              className="w-6 h-6 text-gray-200 animate-spin fill-[#978667]"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <>
                          <h2 className="font-bold text-xl">Pay 50% amount</h2>
                          <p className="text-[#039E00] font-bold text-xl">{0.5 * Grandtotal} {currency}</p>
                        </>
                      )}

                    </button> */}
                    <button onClick={() => { CreateBookingFull() }} className={`py-3 border w-full sm:w-[350px] bg-[${color}] text-white mx-auto hover:bg-[#005BA4]/80 transition-all duration-300  rounded-lg shadow-sm shadow-[#978667] flex items-center justify-around`} style={{ border: `1px solid ${color}` }}>
                      {loader_100 ? (
                        <div className="flex justify-center items-center">
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              className="w-6 h-6 text-gray-200 animate-spin fill-[#978667]"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <>
                          <span className="font-bold text-xl capitalize">confirm booking</span>
                          {/* <p className="text-[#039E00] font-bold text-xl">{Grandtotal} {currency}</p> */}
                        </>
                      )}

                    </button>
                  </> : <div className="min-h-[58px] border   rounded-lg shadow-sm shadow-[#978667] flex items-center justify-around" style={{ border: `1px solid ${color}` }}>
                    <h2 className="font-bold text-xl">Online Payment Disabled</h2>
                    {/* <p className="text-[#039E00] font-bold text-xl">400 INR</p> */}
                  </div> : <div className="min-h-[58px] border   rounded-lg shadow-sm shadow-[#978667] flex items-center justify-around" style={{ border: `1px solid ${color}` }}>
                    <h2 className="font-bold text-xl">Select more rooms</h2>
                    {/* <p className="text-[#039E00] font-bold text-xl">400 INR</p> */}
                  </div>}

                <div className="flex flex-col items-center justify-center gap-2">
                  <p className="text-[#1D619C] italic font-medium">
                    By making this booking, you are accepting our terms and
                    conditions
                  </p>
                  <div>
                    <img src={visa} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="lg:w-[40%] px-5 md:px-10 rounded-md  py-6 max-md:order-1 shadow-xl">
          <h2 className="text-[#1D619C] capitalize text-center  font-bold text-3xl ">
            Booking Summary
          </h2>
          <div className="mt-8 justify-center">

            <table className="w-full">

              <tbody className=" w-full gap-2 capitalize">
                <tr>
                  <td className="py-2"><p className="text-[#000000] font-normal capitalize md:text-lg">Check in</p></td>
                  <td>
                    <p className="text-[#153B5B] md:text-lg text-sm font-bold">
                      <p className="text-[#153B5B] md:text-lg text-end text-sm font-bold">
                        {changeDateFormat(selectedDate)}
                      </p>
                    </p>
                  </td>
                </tr>
                <tr >
                  <td className="py-2">
                    <p className="text-[#000000] font-normal capitalize md:text-lg">Check Out</p>
                  </td>
                  <td>
                    <p className="text-[#153B5B] md:text-lg text-end text-sm font-bold">
                      {changeDateFormat(nextselectedDate)}
                    </p>
                  </td>


                </tr>
                <tr className="">
                  <td className=" py-2 flex items-start text-[#000000] font-normal md:text-lg">Rooms</td>
                  <td>
                    <p className="text-[#153B5B] md:text-lg text-end text-sm font-bold">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {Delux !== 0 ? (
                          <div className="right-span" id="Final_checkout">
                            {RoomCategoryCombination["DELUX"]}:{" "}
                            {Delux}
                          </div>
                        ) : (
                          ""
                        )}
                        {SuperDelux !== 0 ? (
                          <div className="right-span" id="Final_checkout">
                            {RoomCategoryCombination["SUPERDELUX"]}:{" "}
                            {SuperDelux}
                          </div>
                        ) : (
                          ""
                        )}
                        {Suite !== 0 ? (
                          <div className="right-span" id="Final_checkout">
                            {RoomCategoryCombination["SUITE"]}:{" "}
                            {Suite}
                          </div>
                        ) : (
                          ""
                        )}
                        {Premium !== 0 ? (
                          <span className="right-span" id="Final_checkout">
                            {RoomCategoryCombination["PREMIUM"]}:{" "}
                            {Premium}
                          </span>
                        ) : (
                          ""
                        )}
                        {PremiereRetreat !== 0 ? (
                          <span className="right-span" id="Final_checkout">
                            {RoomCategoryCombination["PremiereRetreat"]}:{" "}
                            {PremiereRetreat}
                          </span>
                        ) : (
                          ""
                        )}
                        {EliteSuite !== 0 ? (
                          <span className="right-span" id="Final_checkout">
                            {RoomCategoryCombination["EliteSuite"]}:{" "}
                            {EliteSuite}
                          </span>
                        ) : (
                          ""
                        )}
                        {GrandDeluxe !== 0 ? (
                          <span className="right-span" id="Final_checkout">
                            {RoomCategoryCombination["GrandDeluxe"]}:{" "}
                            {GrandDeluxe}
                          </span>
                        ) : (
                          ""
                        )}
                        {ImperialSuite !== 0 ? (
                          <span className="right-span" id="Final_checkout">
                            {RoomCategoryCombination["ImperialSuite"]}:{" "}
                            {ImperialSuite}
                          </span>
                        ) : (
                          ""
                        )}
                        {SupremeRetreat !== 0 ? (
                          <span className="right-span" id="Final_checkout">
                            {RoomCategoryCombination["SupremeRetreat"]}:{" "}
                            {SupremeRetreat}
                          </span>
                        ) : (
                          ""
                        )}
                        {RoyalDeluxe !== 0 ? (
                          <span className="right-span" id="Final_checkout">
                            {RoomCategoryCombination["RoyalDeluxe"]}:{" "}
                            {RoyalDeluxe}
                          </span>
                        ) : (
                          ""
                        )}
                        {PrestigeSuite !== 0 ? (
                          <span className="right-span" id="Final_checkout">
                            {RoomCategoryCombination["PrestigeSuite"]}:{" "}
                            {PrestigeSuite}
                          </span>
                        ) : (
                          ""
                        )}
                        {ExclusiveRetreat !== 0 ? (
                          <span className="right-span" id="Final_checkout">
                            {RoomCategoryCombination["ExclusiveRetreat"]}:{" "}
                            {ExclusiveRetreat}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="py-2"> <p className="text-[#000000] font-normal md:text-lg">
                    No. of nights
                  </p></td>
                  <td>  <p className="text-[#153B5B] text-end md:text-lg text-sm font-bold">
                    {Nights}
                  </p></td>
                </tr>
                <tr>
                  <td className="py-2"><p className="text-[#000000]  font-normal md:text-lg">
                    {" "}
                    No. of guests
                  </p></td>
                  <td className="py-2"> <p className="text-[#153B5B] text-end md:text-lg text-sm font-bold">
                    {Adult} adults, {kids} kids
                  </p></td>
                </tr>
                {/* Add more rows as needed */}
              </tbody>
            </table>
            {/* <div className="flex flex-col gap-3">
              <p className="text-[#000000] font-normal md:text-lg">Check in</p>
              <p className="text-[#000000] font-normal md:text-lg">Check Out</p>
              <p className="text-[#000000] font-normal md:text-lg">Rooms</p>
              <p className="text-[#000000] font-normal md:text-lg">
                No. of nights
              </p>
              <p className="text-[#000000] font-normal md:text-lg">
                {" "}
                No. of guests
              </p>
            </div> */}
            {/* <div className="flex flex-col gap-4"> */}
            {/* <p className="text-[#153B5B] md:text-lg text-sm font-bold">
                {changeDateFormat(selectedDate)}
              </p>
              <p className="text-[#153B5B] md:text-lg text-sm font-bold">
                {changeDateFormat(nextselectedDate)}
              </p>
              <p className="text-[#153B5B] md:text-lg text-sm font-bold">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {Delux !== 0 ? (
                    <div className="right-span" id="Final_checkout">
                      {RoomCategoryCombination["DELUX"]}:-{" "}
                      {Delux}
                    </div>
                  ) : (
                    ""
                  )}
                  {SuperDelux !== 0 ? (
                    <div className="right-span" id="Final_checkout">
                      {RoomCategoryCombination["SUPERDELUX"]}:-{" "}
                      {SuperDelux}
                    </div>
                  ) : (
                    ""
                  )}
                  {Suite !== 0 ? (
                    <div className="right-span" id="Final_checkout">
                      {RoomCategoryCombination["SUITE"]}:-{" "}
                      {Suite}
                    </div>
                  ) : (
                    ""
                  )}
                  {Premium !== 0 ? (
                    <span className="right-span" id="Final_checkout">
                      {RoomCategoryCombination["PREMIUM"]}:-{" "}
                      {Premium}
                    </span>
                  ) : (
                    ""
                  )}
                  {PremiereRetreat !== 0 ? (
                    <span className="right-span" id="Final_checkout">
                      {RoomCategoryCombination["PremiereRetreat"]}:-{" "}
                      {PremiereRetreat}
                    </span>
                  ) : (
                    ""
                  )}
                  {EliteSuite !== 0 ? (
                    <span className="right-span" id="Final_checkout">
                      {RoomCategoryCombination["EliteSuite"]}:-{" "}
                      {EliteSuite}
                    </span>
                  ) : (
                    ""
                  )}
                  {GrandDeluxe !== 0 ? (
                    <span className="right-span" id="Final_checkout">
                      {RoomCategoryCombination["GrandDeluxe"]}:-{" "}
                      {GrandDeluxe}
                    </span>
                  ) : (
                    ""
                  )}
                  {ImperialSuite !== 0 ? (
                    <span className="right-span" id="Final_checkout">
                      {RoomCategoryCombination["ImperialSuite"]}:-{" "}
                      {ImperialSuite}
                    </span>
                  ) : (
                    ""
                  )}
                  {SupremeRetreat !== 0 ? (
                    <span className="right-span" id="Final_checkout">
                      {RoomCategoryCombination["SupremeRetreat"]}:-{" "}
                      {SupremeRetreat}
                    </span>
                  ) : (
                    ""
                  )}
                  {RoyalDeluxe !== 0 ? (
                    <span className="right-span" id="Final_checkout">
                      {RoomCategoryCombination["RoyalDeluxe"]}:-{" "}
                      {RoyalDeluxe}
                    </span>
                  ) : (
                    ""
                  )}
                  {PrestigeSuite !== 0 ? (
                    <span className="right-span" id="Final_checkout">
                      {RoomCategoryCombination["PrestigeSuite"]}:-{" "}
                      {PrestigeSuite}
                    </span>
                  ) : (
                    ""
                  )}
                  {ExclusiveRetreat !== 0 ? (
                    <span className="right-span" id="Final_checkout">
                      {RoomCategoryCombination["ExclusiveRetreat"]}:-{" "}
                      {ExclusiveRetreat}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </p>
              <p className="text-[#153B5B] md:text-lg text-sm font-bold">
                {Nights}
              </p> */}
            {/* <p className="text-[#153B5B] md:text-lg text-sm font-bold">
                2
              </p> */}
            {/* <p className="text-[#153B5B] md:text-lg text-sm font-bold">
                {Adult} adults, {kids} kids
              </p> */}
            {/* </div> */}
          </div>
          <hr className="mt-10" />
          <div className="flex flex-col gap-2 mt-5">
            <div className="flex justify-between text-[#153B5B] italic text-sm">
              <p>Sub Total</p>
              <p>{Subtotal} {currency}</p>
            </div>
            <div className="flex justify-between text-[#153B5B] italic text-sm">
              <p>Taxes and fees</p>
              <p>{Taxes} {currency}</p>
            </div>
          </div>
          <hr className="mt-5" />

          <div className="flex justify-between text-[#153B5B] mt-4">
            <h2 className="text-xl font-bold uppercase">GRAND TOTAL</h2>
            <p className="text-xl font-bold">{Grandtotal} {currency}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutDetails;
