import React, { Suspense, useContext, useState } from "react";
import { FaPhone, FaWhatsapp } from "react-icons/fa";
import { Link, Route, Routes } from "react-router-dom";
import AuthContext from "./context/AuthProvider";

import About from "./components/About";
import Amenities from "./components/Amenities";
import CardsRoom from "./components/CardsRoom";
import CheckoutDetails from "./components/CheckoutDetails";
import Footer from "./components/Footer";
import Form from "./components/Form";
import Meta from "./components/Meta";
import Navbar, { scrollToSection } from "./components/Navbar";
import Success from "./components/Success";
import Login from "./components/Login";
import Register from "./components/Register";
import Profile from "./components/Profile";
import Booking from "./components/Booking";
const Banner = React.lazy(() => import("./components/Banner"));
const Rooms = React.lazy(() => import("./components/Rooms"));
const Gallery = React.lazy(() => import("./components/Gallery"));
const Nearby = React.lazy(() => import("./components/Nearby"));

function App() {
  const [isBookClick, setisBookClick] = useState(false);
  const {
    websiteData,
    loading,
    setLoading,
    Adult,
    Delux,
    SuperDelux,
    Suite,
    Premium,
    PremiereRetreat,
    EliteSuite,
    GrandDeluxe,
    ImperialSuite,
    SupremeRetreat,
    RoyalDeluxe,
    PrestigeSuite,
    ExclusiveRetreat,
    DeluxAdult,
    SuperDeluxAdult,
    SuiteAdult,
    PremiumAdult,
    PremiereRetreatAdult,
    EliteSuiteAdult,
    GrandDeluxeAdult,
    ImperialSuiteAdult,
    SupremeRetreatAdult,
    RoyalDeluxeAdult,
    PrestigeSuiteAdult,
    ExclusiveRetreatAdult,
    roomsData,
    setRoomsData,
    Payment,
    BookingEngineRooms,
    maxAdult,
    setmaxAdult,
    isLoginPopup,
    isRegisterPopup,
  } = useContext(AuthContext);

  const BookFinalize = () => {
    scrollToSection("checkoutdetails");
    let madult = 0;
    if (Delux > 0) {
      setmaxAdult((madult += Delux * DeluxAdult));
    }
    if (SuperDelux > 0) {
      setmaxAdult((madult += SuperDelux * SuperDeluxAdult));
    }
    if (Suite > 0) {
      setmaxAdult((madult += Suite * SuiteAdult));
    }
    if (Premium > 0) {
      setmaxAdult((madult += Premium * PremiumAdult));
    }
    if (PremiereRetreat > 0) {
      setmaxAdult((madult += PremiereRetreat * PremiereRetreatAdult));
    }
    if (EliteSuite > 0) {
      setmaxAdult((madult += EliteSuite * EliteSuiteAdult));
    }
    if (GrandDeluxe > 0) {
      setmaxAdult((madult += GrandDeluxe * GrandDeluxeAdult));
    }
    if (ImperialSuite > 0) {
      setmaxAdult((madult += ImperialSuite * ImperialSuiteAdult));
    }
    if (SupremeRetreat > 0) {
      setmaxAdult((madult += SupremeRetreat * SupremeRetreatAdult));
    }
    if (RoyalDeluxe > 0) {
      setmaxAdult((madult += RoyalDeluxe * RoyalDeluxeAdult));
    }
    if (PrestigeSuite > 0) {
      setmaxAdult((madult += PrestigeSuite * PrestigeSuiteAdult));
    }
    if (ExclusiveRetreat > 0) {
      setmaxAdult((madult += ExclusiveRetreat * ExclusiveRetreatAdult));
    }

    setisBookClick(true);
  };

  const color = "#EC3237";

  console.log(" line numer", roomsData);
  console.log(websiteData);
  return (
    <>
      <Meta
        Data={{
          title: "Home",
          description: "this is description",
          favicon: websiteData?.Footer?.Logo,
        }}
      />

      {!loading ? (
        <div className="overflow-hidden relative ">
          <Navbar
            logo={websiteData?.Footer?.Logo}
            name={"Ebenerzer Suite"}
            color={color}
            facebook={websiteData?.Links?.Facebook}
            instagram={websiteData?.Links?.Instagram}
            tripadvisors={websiteData?.Links?.Tripadvisor}
          />

          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Suspense
                    fallback={<div className="h-[640px] bg-gray-300"></div>}
                  >
                    <Banner
                      bannerImage={websiteData?.Banner[0]?.url}
                      // bannerImage={
                      //   "https://eazotel-client-images.s3.ap-south-1.amazonaws.com/greekvillas/Images/banner.png"
                      // }
                      color={color}
                    />
                  </Suspense>

                  {Payment === "None" ? (
                    <div className="maxwidth px-5 !mt-10" id="rooms">
                      {BookingEngineRooms.length !== 0 ? (
                        <h2
                          className=" text-3xl text-center text-black py-2 font-bold"
                          style={{ color: color }}

                        >
                          Selected Rooms
                        </h2>
                      ) : (
                        ""
                      )}
                      {BookingEngineRooms.sort(
                        (a, b) =>
                          (parseInt(a.price, 10) || 0) -
                          (parseInt(b.price, 10) || 0)
                      ).map((rooms, index) => {
                        return (
                          <CardsRoom
                            Index={index}
                            data={rooms}
                            BookFinalize={BookFinalize}
                            color={color}
                          />
                        );
                      })}
                      {(Delux !== 0 ||
                        SuperDelux !== 0 ||
                        Suite !== 0 ||
                        Premium !== 0 ||
                        PremiereRetreat !== 0 ||
                        EliteSuite !== 0 ||
                        GrandDeluxe !== 0 ||
                        ImperialSuite !== 0 ||
                        SupremeRetreat !== 0 ||
                        RoyalDeluxe !== 0 ||
                        PrestigeSuite !== 0 ||
                        ExclusiveRetreat !== 0) &&
                        !isBookClick ? (
                        <Link
                          id="summery"
                          className={`border w-max -right-7 rounded-t-md z-[99999]  bottom-[50%] -rotate-90 fixed py-4 px-5  lg:-rotate-90 hover:bg-white hover:text-[#005BA4] para text-white bg-[#005BA4] border-[#005BA4]`}
                          // style={{ backgroundColor: `${color}` }}
                          onClick={() => {
                            BookFinalize();
                          }}
                        >
                          Book Now
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  <div id="checkoutdetails">
                    {BookingEngineRooms.length > 0 &&
                      isBookClick &&
                      (Delux !== 0 ||
                        SuperDelux !== 0 ||
                        Suite !== 0 ||
                        Premium !== 0 ||
                        PremiereRetreat !== 0 ||
                        EliteSuite !== 0 ||
                        GrandDeluxe !== 0 ||
                        ImperialSuite !== 0 ||
                        SupremeRetreat !== 0 ||
                        RoyalDeluxe !== 0 ||
                        PrestigeSuite !== 0 ||
                        ExclusiveRetreat !== 0) &&
                      Payment === "None" ? (
                      <CheckoutDetails color={color} />
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    {BookingEngineRooms.length > 0 &&
                      isBookClick &&
                      (Delux !== 0 ||
                        SuperDelux !== 0 ||
                        Suite !== 0 ||
                        Premium !== 0 ||
                        PremiereRetreat !== 0 ||
                        EliteSuite !== 0 ||
                        GrandDeluxe !== 0 ||
                        ImperialSuite !== 0 ||
                        SupremeRetreat !== 0 ||
                        RoyalDeluxe !== 0 ||
                        PrestigeSuite !== 0 ||
                        ExclusiveRetreat !== 0) &&
                      Payment !== "None" ? (
                      <Success color={color} />
                    ) : (
                      ""
                    )}
                  </div>

                  <About
                    heading={websiteData?.About?.Heading}
                    text={websiteData.About?.Text}
                    imageUrl={websiteData.About?.url}
                    videoUrl={websiteData.About?.video_url}
                    sectionData={websiteData.SectionTitles.About}
                    color={color}
                  />

                  <Suspense fallback={<div>Loading...</div>}>
                    <Rooms
                      roomsData={roomsData}
                      sectionData={websiteData?.SectionTitles?.Rooms}
                      color={color}
                    />
                  </Suspense>

                  <Amenities
                    sectionData={websiteData.SectionTitles.Amenities}
                    color={color}
                  />
                  {/* gallery component */}

                  <Suspense fallback={<div>Loading...</div>}>
                    <Gallery
                      sectionData={websiteData.SectionTitles.Gallery}
                      color={color}
                    />
                  </Suspense>

                  <Suspense fallback={<div>Loading...</div>}>
                    <Nearby
                      color={color}
                      nearbydatas={websiteData?.Nearby}
                      sectionData={websiteData.SectionTitles.Nearby}
                    />
                  </Suspense>
                  <Form
                    color={color}
                    city={websiteData?.Footer?.City}
                    address={websiteData?.Footer?.Address}
                    phone={websiteData?.Footer?.Phone}
                    email={websiteData?.Footer?.Email}
                    location={websiteData?.Location}
                    sectionData={websiteData?.PagesTitles?.Contact}
                  />
                </>
              }
            />

            <Route path="/profile" element={<Profile />} />
            <Route path="/booking" element={<Booking />} />
          </Routes>

          <Footer
            name={"Red Rock Villas"}
            color={color}
            address={websiteData?.Footer?.Address}
            city={websiteData?.Footer?.City}
            email={websiteData?.Footer?.Email}
            logo={websiteData?.Footer?.Logo}
            newsletterText={websiteData?.Footer?.NewsLetterText}
            phone={websiteData?.Footer?.Phone}
            whatsApp={websiteData?.Footer?.WhatsApp}
            facebook={websiteData?.Links?.Facebook}
            instagram={websiteData?.Links?.Instagram}
            tripadvisors={websiteData?.Links?.Tripadvisor}
            linkedin={websiteData?.Links?.Linkedin}
            twitter={websiteData?.Links?.twitter}
            youtube={websiteData?.Links?.Youtube}
          />

          <Link
            Link
            to={`tel:${websiteData?.Footer?.Phone}`}
            target="_blank"
            className=" whatsapp-button inline-block fixed bottom-[20px] left-[20px] w-[50px] h-[50px] p-[12px]  rounded-full text-white z-10 text-[25px] overflow-hidden"
            style={{ backgroundColor: color ? color : "#978667" }}
          >
            <span className="sr-only">what's app</span>{" "}
            <FaPhone className="transform rotate-[100deg]" />
          </Link>

          {/* Whats app******** */}
          <Link
            Link
            to={`https://wa.me/${websiteData?.Footer?.WhatsApp}`}
            target="_blank"
            className="whatsapp-button inline-block fixed bottom-[20px] right-[20px] w-[50px] h-[50px] bg-[#4DC251] p-[12px]  rounded-full text-white z-10 text-[25px] overflow-hidden"
          >
            <span className="sr-only">what's app</span> <FaWhatsapp />
          </Link>
        </div>
      ) : (
        <div className="flex justify-center items-center h-[90vh]">
          <div role="status">
            <svg
              aria-hidden="true"
              className="w-10 h-10 text-gray-200 animate-spin"
              viewBox="0 0 100 101"
              fill={color}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {isLoginPopup && <Login color={color} />}

      {isRegisterPopup && <Register color={color} />}
    </>
  );
}

export default App;
